import React from 'react';

function SingleList({singlePokemon, fetchSetDetail}) {

    function handleDetail() {
        fetchSetDetail(singlePokemon.name)
    }

    return (
        <div onClick={handleDetail} className="list__item">{singlePokemon.name}</div>
    );
}

export default SingleList;

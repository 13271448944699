import React, {useState, useEffect} from "react";
import PokemonList from "./Parts/PokemonList";
import Detail from "./Parts/Detail";
import axios from 'axios';
// import logo from './logo.svg';

import './App.css';
import Pagination from "./Parts/Pagination";

function App() {

    const [pokemon, setPokemon] = useState([]);
    const [currentUrl, setCurrentUrl] = useState('https://pokeapi.co/api/v2/pokemon');
    const [nextPageUrl, setNextPageUrl] = useState();
    const [prevPageUrl, setPrevPageUrl] = useState();
    const [loading, setLoading] = useState(true);
    const [detail, setDetail] = useState();

    // const [coffee, setCoffee] = useState([
    //         {
    //             name: 'arabica'
    //         },
    //         {
    //             name: 'jihlavanka'
    //         }
    // ]);

    useEffect(() => {
        setLoading(true)
        let cancel
        axios.get(currentUrl, {
            cancelToken: axios.CancelToken(c => cancel = c)
        }).then(res => {
            // console.log(res.data)
            setLoading(false)
            // setPokemon(res.data.results.map(p => p.name))
            setPokemon(res.data.results)

            setNextPageUrl(res.data.next)
            setPrevPageUrl(res.data.previous)
        })

        return () => {
            cancel();
        }
    }, [currentUrl])

    function gotoNextPage() {
        setCurrentUrl(nextPageUrl)
    }

    function gotoPrevPage() {
        setCurrentUrl(prevPageUrl)
    }

    // function add() {
    //     setCoffee([...coffee, {
    //         name: 'prostě kafe'
    //     }]);
    // }

    // if(loading) return 'Loading...'

    function handleClose() {
        setDetail(null);
    }

    function fetchSetDetail(id) {
        let cancel
        axios.get('https://pokeapi.co/api/v2/pokemon/' + id, {
            cancelToken: axios.CancelToken(c => cancel = c)
        })
            .then(res => {
                // console.log(res)
                setDetail(res.data)
            })

        return () => {
            cancel();
        }
    }


  return (

      <div>
          <div className="text-2xl md:text-5xl text-center mt-8">Pokedex</div>

          <div className="main border-2 border-black container max-w-4xl mx-auto my-6 p-8">
              {detail &&
              <div className="flex justify-between">
                  <div className="flex">
                    {detail.id > 1 && <div onClick={() => fetchSetDetail(detail.id - 1)} className="border-2 border-black hover:bg-black hover:text-white px-4 py-2 cursor-pointer select-none mr-4">PREV</div>}
                    <div onClick={() => fetchSetDetail(detail.id + 1)} className="border-2 border-black hover:bg-black hover:text-white px-4 py-2 cursor-pointer select-none">NEXT</div>
                  </div>
                  <div onClick={handleClose} className="border-2 border-black hover:bg-black hover:text-white px-4 py-2 cursor-pointer select-none">X</div>
              </div>
              }
              {/*{coffee.map((item, index) => (*/}
              {/*    <li key={index}>*/}
              {/*        {item.name}*/}
              {/*    </li>*/}
              {/*))}*/}
              {/*<button onClick={add}>foo</button>*/}
              {/*<hr/>*/}

              {detail && <Detail detail={detail}/>}
              {!detail && <PokemonList pokemon={pokemon} fetchSetDetail={fetchSetDetail}/> }
              {!detail && <div className="h-8"/>}
              {!detail && <Pagination
                  gotoNextPage={nextPageUrl ? gotoNextPage : null}
                  gotoPrevPage={prevPageUrl ? gotoPrevPage : null}
              />}

          </div>
      </div>
  );
}

export default App;

import React from 'react';

function Pagination({gotoPrevPage, gotoNextPage}) {
    return (
        <div className="flex">
            <div className="flex-auto">
                {gotoPrevPage && <button onClick={gotoPrevPage} className="border-2 border-black hover:bg-black hover:text-white px-4 py-2 select-none">Previous page</button>}
            </div>
            <div>
                {gotoNextPage && <button onClick={gotoNextPage} className="border-2 border-black hover:bg-black hover:text-white px-4 py-2 select-none">Next page</button>}
            </div>
        </div>
    );
}

export default Pagination;

import React from 'react';

function Detail({detail}) {

    let srcFront = ''
    let srcBack = ''
    if(detail.sprites) {
        srcFront = detail.sprites.front_default;
        srcBack = detail.sprites.back_default;
    }

    return (
        <div className="mt-8 mb-4">

            <h1 className="text-2xl md:text-3xl font-bold text-center capitalize">{detail.name}</h1>

            <div className="text-center images">
                <img src={srcFront} alt={detail.name} className="inline-block image image--1" />
                <img src={srcBack} alt={detail.name} className="inline-block image image--2" />
            </div>


            <div className="text-center">
                Weight: {detail.weight}<br/>
                Height: {detail.height}
            </div>

        </div>
    );
}

export default Detail;

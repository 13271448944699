import SingleList from "./SingleList";

function PokemonList({pokemon, fetchSetDetail}) {

    return (

        <div className="bg-gray-100 grid list p-8 sm:grid-cols-2">
            {/*{pokemon}*/}
            {pokemon.map((obj, index) => (
                <SingleList key={index} singlePokemon={obj} fetchSetDetail={fetchSetDetail}/>
            ))}

            {/*{pokemon.map((p,index) => (*/}
            {/*    <div key={index} onClick={handleDetail}>{p}</div>*/}
            {/*    <SingleList key={index} pokemon={p} setDetail={setDetail}/>*/}
            {/*))}*/}
        </div>
    );
}

export default PokemonList;
